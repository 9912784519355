<template>
  <BModal
    id="modalDetail"
    ref="modalDetail"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="md"
    :centered="true"
    @shown="show()"
    @hide="name = ''"
  >
    <validation-observer ref="formRules"
        v-slot="{ invalid }">
      <div class="text-left my-1 d-flex flex-column gap-15">
        <div class="font-bold text-12 text-black">
          Tambah Sektor Bisnis
        </div>
        <b-col md="12" class="p-0 mb-5">
          <b-form-group
            label="Nama Sektor Bisnis"
            label-class="text-8 font-bold text-black"
          >
            <validation-provider
              #default="{ errors }"
              name="Nama Sektor Bisnis"
              :rules="{ min: 3, required: true, max: 45 }"
            >
              <b-form-input
                v-model="name"
                placeholder="Masukkan Nama"
                :state="errors.length > 0 || submitErrors.name ? false : null"
              />
              <small class="text-danger">{{
                errors[0] || submitErrors.name
              }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <div class="d-flex gap-12 justify-center mt-5">
          <button
            size="md"
            variant="outline-primary"
            class="w-100 button-secondary"
            @click="$bvModal.hide('modalDetail')"
          >
            Batal
          </button>
          <button
            :disabled="invalid || loadingSubmit"
            class="w-100 button-primary"
            @click="submit"
          >
            Submit
          </button>
        </div>
      </div>
    </validation-observer>
  </BModal>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  min,
  max,
  numeric,
} from '@validations'
import { alertError, alertSuccess } from '@toast'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    initialName: {
      type: String,
    },
    idItem: {
      type: Number,
    },
  },
  data() {
    return {
      required,
      min,
      max,

      loading: false,
      loadingSubmit: false,
      name: '',
      submitErrors: {},
      alertError,
      alertSuccess,
    }
  },
  methods: {
    async submit() {
      this.loadingSubmit = true

      const data = {
        partner_category_name: this.name,
      }

      if (!this.initialName) {
        const url = 'v1/business_sectors/store'
        await komtimAxiosIns
          .post(url, data)
          .then(() => {
            const text = 'Berhasil menambahkan data'
            this.alertSuccess(text)
            this.loadingSubmit = false

            this.$bvModal.hide('modalDetail')
            this.$emit('updated')
          })
          .catch(error => {
            this.alertError(error)
            this.loadingSubmit = false

            if (error.response.status === 422) {
              this.submitErrors = Object.fromEntries(
                Object.entries(error.response.data.data).map(
                  ([key, value]) => [key, value[0]],
                ),
              )
            }
          })
      } else {
        const url = `v1/business_sectors/${this.idItem}/update`
        await komtimAxiosIns
          .put(url, data)
          .then(() => {
            const text = 'Berhasil mengubah data'
            this.alertSuccess(text)
            this.loadingSubmit = false

            this.$bvModal.hide('modalDetail')
            this.$emit('updated')
          })
          .catch(error => {
            this.loadingSubmit = false
            this.alertError(error)

            if (error.response.status === 422) {
              this.submitErrors = Object.fromEntries(
                Object.entries(error.response.data.data).map(
                  ([key, value]) => [key, value[0]],
                ),
              )
            }
          })
      }
    },
    show() {
      this.name = this.initialName
    },
  },
}
</script>

<style scoped lang="scss">
@import './style/Index.scss';
</style>
